<template>
  <v-container class="contentContainer">
    <!-- PAGE BANNER - Changelog Title Section -->
    <PageHeader class="pb-4" heading="Changelog"/>
    <!-- MAIN CONTENT AREA - Content Section -->
    <v-row class="mt-0" justify="center">
      <v-col class="contentMaxWidth pb-0">
        <!-- UPDATE - March 17, 2025 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">March 17, 2025</p>
            <p class="accent--text font-weight-bold">Version 4.3.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Course Insights are here!</p>
            <p class="text-body-1">After years of collecting course related statistics, we are finally putting them to good use.</p>
            <img :src="require('@/assets/' + ((store.app.darkMode) ? 'dark-insights.png' : 'insights.png'))"
                 width="100%" class="mt-2"/>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li><strong>Course Insights</strong>
                <ul class="pt-2">
                  <li>
                    We’re excited to announce Course Insights, a brand-new feature that takes all the data at our disposal
                    to create helpful recommendations on when and which professor to take a course with! Each data point
                    requires at least one review/evaluation and associated drop rate for it to be considered, which might
                    result in some courses not having any or partial insights right now
                  </li>
                  <li><strong>Top-ranked semesters: </strong>See the best time to take a course</li>
                  <li><strong>Best professors: </strong>Find out which instructors deliver great experiences</li>
                  <li><strong>Top semester + professor combos: </strong>Discover the perfect pairings for a course</li>
                </ul>
              </li>
              <li><strong>Course Planner</strong>
                <ul class="pt-2">
                  <li>Course insight integration: Course cards will now display helpful alerts if <strong>(1)</strong>
                    a course is not typically offered in the semester you have chosen and <strong>(2)</strong> if a
                    course has higher rankings in other semesters</li>
                  <li>Can now add the same course across multiple years in the event a course needs to be retaken</li>
                  <li>Plans will now be saved and synced across devices for those with accounts</li>
                </ul>
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>

        <!-- UPDATE - December 14, 2024 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">December 14, 2024</p>
            <p class="accent--text font-weight-bold">Version 4.2.0-beta</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Course Planner - Beta 🧩</p>
            <p class="text-body-1">Presenting an exciting new feature!</p>
            <img :src="require('@/assets/' + ((store.app.darkMode) ? 'dark-kanban.png' : 'kanban.png'))"
                 width="100%" class="mt-2"/>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li><strong>Course Planner</strong>
                <ul class="pt-2">
                  <li>
                    We’re excited to announce the beta launch of Course Planner, a brand-new feature on UofT Index
                    designed to make planning your university journey more interactive and efficient!
                  </li>
                  <li>Organize your courses visually by dragging and dropping them into their respective semester column</li>
                  <li>Automatically generate a timetable based on your planned courses</li>
                  <li>Download your plans to save and share</li>
                </ul>
                <div class="mb-0"><strong class="warning--text">Note: </strong>
                  <ul class="pt-2">
                    <li>
                      Users can add a maximum of 6 years and 6 courses per semester/year (18 courses/year)
                    </li>
                    <li>
                      While mobile access is available, certain features like drag-and-drop and search functionality may be limited
                    </li>
                    <li>
                      Plans will be saved locally in your browser during the beta
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <p class="text-body-1 pt-4 font-weight-medium">If there are any bugs or if you find something that should
              be implemented differently or added, please make sure to visit
              <a href="https://uoftindex.canny.io/kanban-course-planner-beta" target="_blank"
                 class="accent--text text-body-1 font-weight-bold">Kanban/Course Planner - Beta</a> and let us know!
            </p>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>

        <!-- UPDATE - October 19, 2024 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">October 19, 2024</p>
            <p class="accent--text font-weight-bold">Version 4.1.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Minor Update</p>
            <p class="text-body-1">Just some minor improvements and new features.</p>

            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li>Waitlist information is now available when viewing course availability</li>
              <li>New filter functionality within the directory search dropdowns for faster searching and less scrolling</li>
              <li>Added tooltips for semester chips in the course directory when hovering over them</li>
              <li>Persistent sort icons beside column headers that allow sorting in the course directory</li>
              <li>Update misc information on the homepage</li>
              <li>New verified icon on the reviews based from UofT course evaluations</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>

        <!-- UPDATE - March 27, 2024 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">March 27, 2024</p>
            <p class="accent--text font-weight-bold">Version 4.0.1</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Bug Fixes</p>
            <p class="text-body-1">Just some minor fixes.</p>

            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">The bug fixes:</div>
            </div>
            <ul>
              <li>Increased the max character count for course reviews from 500 -> 1000</li>
              <li>Added a new error message when the comment length exceeds the maximum to make it more obvious for users</li>
              <li>Fixed an issue where selecting Like/Dislike on a course review when not signed would cause an error</li>
              <li>Updated the professor page which would show it was loading indefinitely if the professor had no current courses</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul>
              <li>Advanced timetable and directory search functionality</li>
              <li>Temporal-based search and viewing capabilities for drop rates</li>
              <li>Viewing reviews linked a professor right from the professor page</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>

        <!-- UPDATE - March 17, 2024 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">March 17, 2024</p>
            <p class="accent--text font-weight-bold">Version 4.0.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">UofT Index v4.0 🎉</p>
            <p class="text-body-1">A new year, a new major version!</p>

            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li><strong>Course Reviews</strong>
                <ul class="pt-2">
                  <li>
                    By popular demand, we are introducing course reviews in favour of simple ratings. In addition to
                    being able to rate a course, students are now able to add a review/comment and provide even more
                    insight into their experience with a particular course and professor
                  </li>
                  <li>With this feature, individual course reviews are now visible on their respective course-page</li>
                  <li>Reviews can be liked/disliked, filtered, and sorted</li>
                  <li>Students are also able to view and edit their reviews from the user dashboard</li>
                </ul>
                <div class="mb-0"><strong class="warning--text">Note: </strong>
                  <ul class="pt-2">
                    <li>
                      Account @usernames are displayed on reviews for everyone to see. @usernames can be changed
                      in the dashboard
                    </li>
                    <li>
                      Reviews with grades will not display the grade for privacy reasons. However, all the grades on
                      reviews associated with a course will be visible in the Review Overview section (existing behaviour)
                    </li>
                    <li>
                      Reviews are subject to reporting/flagging by the application and other users. Accounts that
                      receive (3) flagged reviews (which are verified by an admin) may result in, among other things,
                      termination or suspension of rights to use the Site.<br/> Be respectful and follow our
                      <strong class="accent--text" @click="$router.push('/terms')"> Terms of Use </strong>
                    </li>
                  </ul>
                </div>
              </li>
              <li><strong>MyDashboard</strong>
                <ul class="pt-2">
                  <li>The user dashboard has been completely redesigned from the ground up</li>
                  <li>Students can now pin up to (6) timetables on their dashboard for quick access</li>
                  <li>New 'Reviews' section</li>
                </ul>
              </li>
              <li><strong>Other</strong>
                <ul class="pt-2">
                  <li>Updated the course comparison drop rate graph to show the averages for each course</li>
                  <li>Misc UI tweaks</li>
                </ul>
              </li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">The bug fixes:</div>
            </div>
            <ul>
              <li>
                Updated the broken <strong class="accent--text" @click="$router.push('/tree')"> Course Tree </strong>
                link on the homepage
              </li>
              <li>
                Fix timetable selection bug that would prevent adding a course if it was available in multiple
                semesters but one semester didn't required TUT/PRA but another semester did
              </li>
              <li>
                Fix semester chip issue that would show the incorrect timetable section if there are multiple semester
                options for the same season (i.e. Fall 2023 & Fall 2024)
              </li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul>
              <li>Advanced timetable and directory search functionality</li>
              <li>Temporal-based search and viewing capabilities for drop rates</li>
              <li>Viewing reviews linked a professor right from the professor page</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>

        <!-- UPDATE - Sep 11, 2023 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">September 11, 2023</p>
            <p class="accent--text font-weight-bold">Version 3.3.1</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Minor Update</p>
            <p class="text-body-1">Just some minor fixes.</p>

            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">The bug fixes:</div>
            </div>
            <ul>
              <li>Prevent in-active sections from being assigned when generating a timetable.</li>
              <li>Show weekend courses in Timetable if they exist</li>
              <li>Fix an issue where some data in the Timetable right-side panel may display incorrect information</li>
              <li>Ensure the location of a course's section is always up-to-date when viewing a timetable</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>

        <!-- UPDATE - Jun 25, 2023 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">June 25, 2023</p>
            <p class="accent--text font-weight-bold">Version 3.3.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Minor Update</p>
            <p class="text-body-1">Just some minor fixes and a new filter option.</p>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li>Added the ability to filter the course directory or timetable courses by the number of ratings</li>
              <li>Minor UI tweaks on the homepage</li>
              <li>Course tags will only show the count if the value is more than 1</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul>
              <li>Advanced timetable and directory search functionality</li>
              <li>Temporal-based search and viewing capabilities for drop rates</li>
              <li>Course evaluations and reviews</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - April 22, 2023 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">April 22, 2023</p>
            <p class="accent--text font-weight-bold">Version 3.2.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Another New Feature!</p>
            <p class="text-body-1">This release brings a new feature requested by one of our users.</p>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li>Post-requisite Trees: From the
                <strong class="accent--text" @click="$router.push('/tree')"> Course Tree </strong> page, users will
                now be able to select whether they want to view:
                <ul class="pt-2">
                  <li>
                    <strong>Prerequisites:</strong> Courses required to take a particular course
                  </li>
                  <li>
                    <strong>Post-requisites:</strong> Courses that a particular course is used as a prerequisite for
                    (i.e. courses that a given course "unlocks")
                  </li>
                </ul>
                <p class="mb-0"><strong class="warning--text">Note: </strong>The post-requisite tree only shows the
                  post-requisites one level at a time. If users want to view the post-requisite tree for a particular
                  course on the current post-requisite tree, click on the course node and watch the tree grow!</p>
              </li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">The bug fixes:</div>
            </div>
            <ul>
              <li>Fixed the course list table colour on the user dashboard page when in dark mode</li>
              <li>Updated the outdated images to reflect the rating and evaluation changes made in Version 3.1.0</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul>
              <li>Advanced timetable and directory search functionality</li>
              <li>Temporal-based search and viewing capabilities for drop rates</li>
              <li>Course evaluations and reviews</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - April 06, 2023 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">April 6, 2023</p>
            <p class="accent--text font-weight-bold">Version 3.1.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">More Data & Enhancements</p>
            <p class="text-body-1">Roughly a month after v3.0, we are back again with a pretty big update!</p>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li>We have incorporated course evaluations into our course ratings with the goal of offering
              even more insight into courses and what students think of them</li>
              <li>The course directory now features the following new fields:
                <ul class="pt-2">
                  <li>Workload</li>
                  <li>Recommend</li>
                  <li>Current Offerings</li>
                  <li>Ratings (Only on large desktops)</li>
                </ul>
              </li>
              <li>The professor page now shows the professors full name to reduce ambiguity</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">The bug fixes:</div>
            </div>
            <ul>
              <li>Fixed a timetable bug that where a course might jump between different semesters when
              shuffling if it is available for multiple semesters</li>
              <li>The courses a professor is currently teaching is now properly supported by most recent offering</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul>
              <li>Post-requisite Trees (See what other courses a particular course is used as a prerequisite for)</li>
              <li>Advanced timetable and directory search functionality</li>
              <li>Temporal-based search and viewing capabilities for drop rates</li>
              <li>Course evaluations and reviews</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - March 3, 2023 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">March 3, 2023</p>
            <p class="accent--text font-weight-bold">Version 3.0.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">UofT Index v3.0 🎉</p>
            <p class="text-body-1">Just in time for the summer semester, version 3.0 is finally here!</p>
            <img :src="require('@/assets/' + ((store.app.darkMode) ? 'dark-home.png' : 'home.png'))"
                 width="100%" class="mt-2"/>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li>Complete UI redesign across the entire website!</li>
              <li><strong class="accent--text" @click="store.app.commandModal = true">Command Panel:</strong>
                Quickly search for a course or saved timetable from anywhere on the website by using the new Command
                Panel rather than having to navigate back to the directory or timetable page! It can be activated
                either using your keyboard (Cmd/Ctrl + K) or by clicking on the shortcut on the top right of any
                page.</li>
              <li><strong>Timetable Loader:</strong> The new timetable loader feature is designed for students who
                already know what courses they want to take and simply want to quickly view the potential schedules.
                Students just need to enter the list of courses they want to take, select a semester, and then let our
                system do the rest!</li>
              <li>The prerequisite tree now supports <strong>Prerequisite Nodes</strong> which will show the
                required combination of courses required</li>
              <li><strong>Download/Export</strong> options for the following features:
                <ul class="pt-2">
                  <li>Timetable schedules</li>
                  <li>Prerequisite Trees</li>
                </ul>
              </li>
              <li>We've increased the total number of timetables you can create and save to: <strong>24</strong></li>
              <li>A bunch of other stuff that I can't remember while updating this... Have fun exploring! 😅</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">The bug fixes:</div>
            </div>
            <ul>
              <li>Fixed search filter spacing issue on smaller screens</li>
              <li>Resolved directory row click inconsistency bug on mobile devices</li>
              <li>Course rating issue preventing users from adding new ratings has been resolved</li>
              <li>Fixed a filter issue on the professor page preventing the desired courses for a particular semester
                from being shown</li>
              <li>The timetable notes/restrictions are now collapsed by default for mobile users</li>
            </ul>
            <p class="text-body-1 pt-4 font-weight-medium">If there are any bugs or if you find something that should
              be implemented differently, please make sure to visit the
              <strong class="accent--text" @click="$router.push('/feedback')"> feedback page </strong>and let us know!</p>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - January 18, 2023 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">January 18, 2023</p>
            <p class="accent--text font-weight-bold">Version 2.3.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Minor Bug Fixes</p>
            <p class="text-body-1">Just some minor bug fixes ahead of something big...</p>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">The bug fixes:</div>
            </div>
            <ul>
              <li>Fixed another <v-chip small color="warning" class="mx-1 px-2 font-weight-bold">CONFLICT</v-chip>
                logic bug created in the previous version that would label a course as conflicting with itself</li>
              <li>Fixed timetable bug where Async courses or courses without a time & date would not show
                on the calendar</li>
              <li>Improved overall performance for saving & storing timetables</li>
              <li>Other miscellaneous behind the scenes changes ahead of version 3</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul>
              <li class="font-weight-bold"> 👀 VERSION 3.0 ...</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - December 12, 2022 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">December 12, 2022</p>
            <p class="accent--text font-weight-bold">Version 2.2.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Enhancements & Bug Fixes</p>
            <p class="text-body-1">Just some minor enhancements and bug fixes ahead of something big...</p>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li>The professor page has been redesigned to show the professors current courses on a calendar
                for easier viewing</li>
              <li>When viewing a course page or selecting lecture/tutorial times, users will be able to view
              enrolment restrictions/notes (if applicable) right on the page</li>
              <li>Timetable share options in fullscreen-mode have been moved into the scroll-body to give
              laptop-users more space when previewing courses and their sections</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">The bug fixes:</div>
            </div>
            <ul>
              <li>Fixed a conflict logic bug that would label side-by-side courses as
                <v-chip small color="warning" class="mx-1 px-2 font-weight-bold">CONFLICT</v-chip></li>
              <li>Adding a course to a schedule when in fullscreen and viewing two semesters no longer triggers
                course tiles to show all available information</li>
              <li>Fixed quick stats text colour on mobile layout</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul>
              <li class="font-weight-bold"> 👀 VERSION 3.0 ...</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - August 21, 2022 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">August 21, 2022</p>
            <p class="accent--text font-weight-bold">Version 2.1.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">New Features & Improvements</p>
            <p class="text-body-1">After two releases focused on bug fixes we can finally release some cool new features
              and improvements to existing features! + a few minor bug fixes.</p>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li><strong class="accent--text" @click="$router.push('/compare')"> Course Comparison </strong> is here!
                Having difficulty deciding between multiple courses? Users can now compare up to three courses against all the
                data and metrics UofT Index currently provides</li>
              <li><p class="mb-0 font-weight-medium">Timetable Builder & Selecting courses</p>
                <img :src="require('@/assets/' + ((store.app.darkMode) ? 'dark-' + 'changelog-preview.png' : 'changelog-preview.png'))"
                     width="100%" class="mt-2"/>
                <ul class="pt-2">
                  <li>The timetable builder now has a fullscreen mode enabling users with larger screens to take
                    advantage of the extra real estate and view timetables side-by-side</li>
                  <li>Users can now also preview alternative section times for courses they have already added by
                    navigating to the Timetable Builder edit panel and clicking the eye icon when hovering over a
                    course section</li>
                  <li>The popup modal enabling course selection now warns users which sections
                    <v-chip small color="warning" class="mx-1 px-2 font-weight-bold">CONFLICT</v-chip> with a course
                    that already exists in their schedule</li>
                  <li>The popup modal UI has also been tweaked to include the suite of quick stats (Bird count, drop
                    rate, and rating)</li>
                  <li>Existing timetables that were previously saved can now be quickly selected right from the
                    Timetable Builder page eliminating the hassle of visiting the dashboard to do so</li>
                  <li>Courses can now be added to a users timetable schedule right from the course info page</li>
                  <li>When viewing a shared timetable or on mobile, blocked times are no longer visible</li>
                </ul>
              </li>
              <li>Course data for the remaining UofT faculties have been added:
                <ul class="pt-4">
                  <li><strong>The Faculty of Music</strong> --> Music</li>
                  <li><strong>John H. Daniels Faculty of Architecture, Landscape, and Design</strong> -->
                    Architecture and Visual Studies</li>
                </ul>
              </li>
              <li>Google, Microsoft, and GitHub SSO authentication now uses a popup modal exclusively to prevent auth
                issues related to browser settings. Users who previously signed up using an email & password but used
                a SSO provider email can optionally switch to using SSO by clicking one of the providers. Your account
                data will automatically transfer over!</li>
              <li>Course information will now show up when when users are browsing drop rates on the
                <strong class="accent--text" @click="$router.push('/droprate')"> Drop Rates </strong> page and are
                viewing a specific course</li>
              <li>We have launched a public roadmap and feature voting system to keep users in the loop on new and
                ongoing developments and provide a way to voice opinions on new and upcoming features. View
                <a href="https://uoftindex.canny.io/" target="_blank" class="accent--text text-body-1 font-weight-bold">
                  Roadmap & Feature Voting</a>
              </li>
              <li>Last and probably least 😅 we have created an
                <strong class="accent--text" @click="$router.push('/about')"> About Us </strong> page where you can
                find out a little bit more about <strong>UofT Index</strong> and the developers behind the scenes</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">The bug fixes:</div>
            </div>
            <ul>
              <li>Fixed an issue where toggling between the different drop rate modes would navigate users to the wrong
                mode</li>
              <li>Fixed the sort order for the course and professor history section such that the most recent  offering
                always shows up on the right hand side</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul>
              <li>Support for Fall Y-term courses in the timetable builder</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - July 15, 2022 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">July 15, 2022</p>
            <p class="accent--text font-weight-bold">Version 2.0.2</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">More Bug Fixes</p>
            <p class="text-body-1">Even more bug fixes (Thank you to everyone who continue to report and notify us about
              them!) and misc UI tweaks.</p>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">The bug fixes:</div>
            </div>
            <ul>
              <li>Fixed an issue where user display names would get reset after logging back into the app</li>
              <li>Course sections (Lectures, Tutorials, and or Practicals) that are not available on ACORN and disabled
                on the official UofT timetable are now also disabled in the timetable builder</li>
              <li>Longer display names are no longer cutoff on the Dashboard</li>
              <li>Resolved an issue where some users with large timetables would not be able to save them properly</li>
              <li>Fixed a timetable issue where adding blocked time would prevent users from being able to shuffle
                their schedule</li>
              <li>Fixed another timetable issue which prevented users from adding courses that only have a TUT/PRAC and
                no LEC section</li>
              <li>More backend tweaks ahead of the new Faculty of Music and Faculty of Architecture courses</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul>
              <li>Viewing saved timetables right from the Timetable page</li>
              <li>Fullscreen timetable mode and a bunch of other timetable goodies</li>
              <li>Being able to compare courses similar to comparing tech products</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - June 27, 2022 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">June 27, 2022</p>
            <p class="accent--text font-weight-bold">Version 2.0.1</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Minor Bug Fixes</p>
            <p class="text-body-1">Just a quick update to fix some bugs across the website.</p>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">The bug fixes:</div>
            </div>
            <ul>
              <li>Fixed an issue where users would see a "A tutorial must be selected!" error when the current course
              and semester doesn't actually have a tutorial/practical section</li>
              <li>The semester year no longer goes to 2099 when adding blocked time to a timetable</li>
              <li>Fixed a semester chip issue where the course popup would not be auto filtered based on the semester chip
              that the user clicked on</li>
              <li>Backend fixes ahead of new course data that was previously missing</li>
            </ul>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul><li>Viewing saved timetables right from the Timetable page</li></ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - June 22, 2022 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">June 22, 2022</p>
            <p class="accent--text font-weight-bold">Version 2.0.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">UofT Index - Official LAUNCH 🎉</p>
            <p class="text-body-1">It's finally here! Following a successful release at the beginning of the year, we
              are excited to launch version 2 that brings with it a whole bunch of new features and data for users.</p>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li>Given the name change, the website now supports all UofT campuses (UTSC, UTSG, UTM)</li>
              <li>As a result of all this new data, almost every page has been redesigned (yes, even on mobile)</li>
              <li>In addition to bird recommendations, users are now able to add course ratings across a variety
                of metrics to better express their opinions and provide other students even more insight into a course.
                Among others, new metrics include:
                <ul class="pt-4">
                  <li>Overall course rating</li>
                  <li>Liked/Disliked</li>
                  <li>Usefulness & Difficulty</li>
                  <li>Grade or Course Average</li>
                  <li>Course tags and more!</li>
                </ul>
              </li>
              <li>Users can now share course links as well as prerequisite tree links</li>
              <li>Brand new professor page which is accessible when looking at a courses' timetable that shows what
                other courses that professor is teaching as well as their history (Just like course history!)</li>
              <li>Timetable is no longer in BETA!
                <ul class="pt-4">
                  <li>Similar to the directory, users can now filter for certain courses across a variety of criteria</li>
                  <li>New semester icons appear next to courses in the dropdown menu to quickly show when a course is
                    being offered</li>
                  <li>Users can save timetables which are later accessible through their dashboard</li>
                  <li>Timetables can also be shared with others after saving them</li>
                  <li>Per semester 'Shuffle Options' giving users greater control when creating schedules</li>
                </ul>
              </li>
              <li>Prerequisite trees now indicate courses that you have already taken to better see what courses you
                still need to take</li>
              <li>To help users take advantage of the new prerequisite trees and quickly add course ratings, users
                can now load their entire course history from ACORN in one shot from their
                <strong class="accent--text" @click="$router.push('/dashboard')"> Dashboard </strong> page!</li>
              <li>New Single Sign-On (SSO) providers: <strong>Google, Microsoft, and GitHub!</strong><br/>* Users who
                have an existing account that can take advantage of these new providers are recommended to switch over
                by simply selecting the respective SSO option and login with their original email, your account will
                automatically switch over and become verified. (ex. If you previously created an account with your UofT
                email, select Microsoft SSO)</li>
              <li>The <strong class="accent--text" @click="$router.push('/privacy')"> Privacy Policy </strong> has been
                updated & <strong class="accent--text" @click="$router.push('/terms')"> Terms of Use </strong> has been
                added</li>
              <li>Probably a bunch of other things that I can't remember right now... Have fun exploring! 😅</li>
            </ul>

            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">Misc bug fixes & enhancements:</div>
            </div>
            <ul>
              <li>Fixed drop rate map tooltip displaying inaccurate drop rates</li>
              <li>Resolved Scroll Up feature not showing up on certain pages</li>
              <li>Timetable cart no longer shows user-blocked entries</li>
            </ul>

            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul><li>Viewing saved timetables right from the Timetable page</li></ul>
            <p class="text-body-1 pt-4 font-weight-medium">If there are any bugs or if you find something that should
              be implemented differently, please make sure to visit the
              <strong class="accent--text" @click="$router.push('/feedback')"> feedback page </strong>and report it!</p>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - April XX, 2022 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">April 25, 2022</p>
            <p class="accent--text font-weight-bold">Version 1.4.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">BIG Update Incoming!</p>
            <p class="text-body-1">Following Winter 22 exams, here is a pretty big update with new features and major
              UI improvements. This will probably be the last feature-related update for V1 as we have started
              developing V2 ahead of the next school year (Fall 22) with a lot of new features and helpful
              statistics/info coming soon!</p>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li><span class="font-weight-bold">Dynamic Courses:</span> when viewing course information, users can
                now quickly preview courses mentioned within another courses description, prerequisite list, exclusion
                list, etc.</li>
              <li>Quickly add course to your timetable as you browse through the course
                <strong class="accent--text" @click="$router.push('/directory')">directory</strong> by hovering over
                the desired semester for a particular course and selecting whatever time slot suites you</li>
              <li>Brand new <span class="font-weight-bold">Timetable Cart</span> feature which can be accessed from the
                <strong class="accent--text" @click="$router.push('/directory')">directory</strong> or
                <strong class="accent--text" @click="$router.push('/tree')">prerequisites</strong> page providing a
                quick overview of the courses you have in your current timetable
              </li>

              <li>
                Mobile Timetable! Visit the <strong class="accent--text" @click="$router.push('/timetable')"> Timetable </strong> on a mobile device for a much better viewing experience.
              </li>
            </ul>

            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">Misc bug fixes & enhancements:</div>
            </div>
            <ul>
              <li>Fixed the table colour making it hard to read the text when hovering over rows in dark mode</li>
              <li>[Timetable] - Feedback button not working on mobile</li>
            </ul>

            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul><li class="font-weight-bold"> 👀 VERSION 2.0 ...</li></ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - March 9, 2022 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">March 9, 2022</p>
            <p class="accent--text font-weight-bold">Version 1.3.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Even More Features</p>
            <p class="text-body-1">This update brings even more features to the timetable BETA as well as some major
            UI changes.</p>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li>The
                <strong class="accent--text" @click="$router.push('/timetable')">Timetable Builder</strong> now supports:
                <ul>
                  <li class="pt-1"> Block/Unblock time </li>
                  <li> Lock lecture/tutorial in-place </li>
                  <li> Preference for time-of-day and online-only courses </li>
                  <li> Support for asynchronous courses </li>
                  <li> Full support for Summer section! </li>
                </ul>
              </li>
              <li><span class="font-weight-bold">DARK MODE</span> is here! Desktop users can choose between light/dark
              themes using the display icon beside the login/user button. Mobile users can similarly toggle which
              mode they want from the side navigation panel</li>
              <li>Users are now able to login and signup with their <span class="font-weight-bold">Google</span> accounts</li>
            </ul>

            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">Misc bug fixes & enhancements:</div>
            </div>
            <ul>
              <li>[Timetable] - Fix lecture conflicting with its own tutorial</li>
              <li>[Timetable] - Prevent changing event colour when course updates</li>
              <li>[Timetable] - Deleting a course sometimes wouldn't work</li>
              <li>Various UI fixes and improvements</li>
            </ul>

            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul>
              <li> [Timetable] - More UI improvements and features</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - March 1, 2022 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">March 1, 2022</p>
            <p class="accent--text font-weight-bold">Version 1.2.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Timetable BETA & More!</p>
            <p class="text-body-1">This update contains the brand new timetable builder feature BETA, prerequisite
              tree improvements, as well as various backend and UI tweaks.</p>
            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🎂</div> <div class="ml-2">The New Stuff:</div>
            </div>
            <ul>
              <li>
                <strong class="accent--text" @click="$router.push('/timetable')">Timetable Builder: </strong> Quickly generate conflict-free timetables with all of your favourite courses
                <ul>
                  <li class="pt-1"> Add courses for both Fall and Winter </li>
                  <li class="pb-0"> Shuffle timetable for new results each time </li>
                </ul>
              </li>
              <li>The <strong class="accent--text" @click="$router.push('/tree')">prerequisite trees </strong>
                now grey out duplicate courses that are prerequisites for multiple courses so users can get a
                better view of what courses they actually need to take</li>
              <li>New and revised info popups for the prerequisite trees and drop rate screens</li>
              <li>Dedicated maintenance landing page</li>
            </ul>

            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🐞</div> <div class="ml-2">Misc bug fixes & enhancements:</div>
            </div>
            <ul>
              <li>Fixed data types for multiple pages that could lead to console errors</li>
              <li>Server-side cleanup and code refactoring</li>
            </ul>

            <div class="flex flex-row align-center text-body-1 font-weight-medium pt-4 pb-2">
              <div class="text-h6">🛠️</div> <div class="ml-2">Already in the works/under consideration:</div>
            </div>
            <ul>
              <li> [Timetable] - Block-off time</li>
              <li> [Timetable] - Lock courses in-place</li>
              <li> [Timetable] - Filter by Online-only, preferences, and more</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - February 8, 2022 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">February 8, 2022</p>
            <p class="accent--text font-weight-bold">Version 1.1.1</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Minor Bug Fixes</p>
            <p class="text-body-1">Just a quick update to fix some bugs across the website.</p>
            <p class="text-body-1 font-weight-medium pt-4">The Bug Fixes:</p>
            <ul>
              <li>Manually deselecting the bird count or drop rate filter from the dropdown menu in the course
                directory no longer throws an error requiring users to refresh the page</li>
              <li>Fixed router authentication for the sessions page</li>
              <li>Updated server validation error message to be more descriptive</li>
            </ul>
            <p class="text-body-1 font-weight-medium pt-4">Already in the works/under consideration:</p>
            <ul>
              <li>[Prerequisite tree] - Greying out and auto collapsing duplicate courses</li>
              <li>[Prerequisite tree] - Better display of prereq combinations (AND, OR)</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - January 21, 2022 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">January 21, 2022</p>
            <p class="accent--text font-weight-bold">Version 1.1.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Prerequisite Tree Improvements</p>
            <p class="text-body-1">This update adds new accessibility features to the course prerequisite page
            and some minor tweaks in other places.</p>
            <p class="text-body-1 font-weight-medium pt-4">The New Prerequisite Stuff:</p>
            <ul>
              <li>Added in a new Zoom functionality so users won't necessarily need to view large prerequisite
              trees in fullscreen mode</li>
              <li>Desktop users who don't have a trackpad can now drag the tree to scroll rather than trying grab the
              horizontal slider</li>
              <li>The border surrounding the tree is now permanent and no longer only on hover to better separate
              page elements</li>
            </ul>
            <p class="text-body-1 font-weight-medium pt-4">Misc Enhancements:</p>
            <ul>
              <li>Minor UI tweaks on the prerequisites page and on others as well</li>
              <li>Updating this changelog also counts right..?</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - January 2, 2022 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">January 2, 2022</p>
            <p class="accent--text font-weight-bold">Version 1.0.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">UTSC Index - Official LAUNCH!</p>
            <p class="text-body-1">After 2+ years of procrastination and five months of on and off coding,
              UTSC Index has finally arrived! Designed by a student for students, the goal of this website is
              to provide students with the information that matters the most when selecting courses.</p>
            <p class="text-body-1 font-weight-medium pt-4">The 4 main features that this site offers
              and are available to explore:</p>
            <ul>
              <li><strong class="accent--text" @click="$router.push('/tree')">Prerequisite Trees: </strong>
                Quickly check all prerequisite courses that are required to take upper year courses</li>
              <li><strong class="accent--text" @click="$router.push('/directory')">Bird Course Recommendations: </strong>
                Search and view courses that other students have recommended as a bird course</li>
              <li><strong class="accent--text" @click="$router.push('/directory')">Course History: </strong>
                View when and who previously taught the course as well as the drop rate for that session</li>
              <li><strong class="accent--text" @click="$router.push('/droprate')">Drop Rates: </strong>
                Check the drop rate for courses and professors before deciding whether or not to take a course</li>
            </ul>
            <p class="text-body-1 pt-4 font-weight-medium">If there are any bugs or if you find something that should
              be implemented differently, please make sure to visit the
              <strong class="accent--text" @click="$router.push('/feedback')"> feedback page </strong>and report it!</p>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - December 30, 2021 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">December 30, 2021</p>
            <p class="accent--text font-weight-bold">Version 0.5.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">New Mobile Features & Fixes</p>
            <p class="text-body-1">This is probably the last update before launch and with it brings a bunch of
              redesigned UI elements and new mobile functionality.</p>
            <p class="text-body-1 font-weight-medium pt-4">The New Stuff:</p>
            <ul>
              <li>The mobile version of the <strong class="accent--text" @click="$router.push('/directory')"> Course Directory </strong>page
                has been redesigned. Along with the redesign, users can now also update their bird recommendations
                straight from the directory like on the desktop version.<strong class="accent--text"> Note:</strong>
                Adding and removing favourites is still only available through the Dashboard for mobile users.</li>
              <li>Added in a navbar 'Theme Colour' just to make things look a little nicer...</li>
              <li><strong class="accent--text">Scroll Up </strong>feature has been added to dashboard course list
                when users navigate between pages</li>
            </ul>
            <p class="text-body-1 font-weight-medium pt-4">Misc bug fixes & enhancements:</p>
            <ul>
              <li>Fixed the course list styling on the<strong class="accent--text" @click="$router.push('/dashboard')"> MyDashboard </strong>page</li>
              <li>Backend server cleanup</li>
              <li>Other little tweaks here and there that will probably go unnoticed</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - December 28, 2021 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">December 28, 2021</p>
            <p class="accent--text font-weight-bold">Version 0.4.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">More Fixes + New Feature</p>
            <p class="text-body-1">This update contains mostly backend and under the hood fixes and tweaks. That said,
            this update brings one new accessibility feature and various UI tweaks that you may or may not notice...</p>
            <p class="text-body-1 font-weight-medium pt-4">The New Stuff:</p>
            <ul>
              <li>Quickly scroll back to the top of the page using the
                <strong class="accent--text">Scroll Back To Top Button</strong></li>
            </ul>
            <p class="text-body-1 font-weight-medium pt-4">Misc bug fixes & enhancements:</p>
            <ul>
              <li>Various minor UI mods and tweaks</li>
              <li>Backend server improvements</li>
              <li>Updated Login/SignUp page to ensure consistency</li>
              <li>Fixed session store memory leak warnings</li>
              <li>Updated the footer social media and quick link buttons and options</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - December 20, 2021 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">December 20, 2021</p>
            <p class="accent--text font-weight-bold">Version 0.3.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">New Features & Fixes</p>
            <p class="text-body-1">This update contains new functionality and features aimed at providing users
            even more information and quicker access to core functionality.</p>
            <p class="text-body-1 font-weight-medium pt-4">The New Stuff:</p>
            <ul>
              <li>The 'MyCourses' page has been redesigned and renamed as:
                <strong class="accent--text" @click="$router.push('/dashboard')"> MyDashboard </strong></li>
              <li>Users are now able to view a breakdown of their courses that they have added as a favourite
                (i.e. They've taken it or are planning to) based on Field of Study. Additionally, users can quickly filter
                their course list by clicking directly on the bar chart to view a further breakdown of their courses</li>
              <li>The new<strong class="accent--text" @click="$router.push('/dashboard')"> MyDashboard </strong>page also now indicates
                which breadth requirements have been met and those that are still missing (based on their course list)</li>
              <li>User can now recommend bird courses without having to first add the course as a favourite.
                <strong class="accent--text">Note:</strong> The course will still be added as a favourite and so
                the 50 course maximum is still enforced!</li>
            </ul>
            <p class="text-body-1 font-weight-medium pt-4">Misc bug fixes & enhancements:</p>
            <ul>
              <li>Fixed a state management bug which caused the
                <strong class="accent--text" @click="$router.push('/tree')"> Prerequisite Tree </strong>page to reset back to its
                initial state when users return from the course info page after viewing more information about
                the course</li>
              <li>Various minor UI consistency tweaks</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - December 13, 2021 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">December 13, 2021</p>
            <p class="accent--text font-weight-bold">Version 0.2.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">Functionality Update</p>
            <p class="text-body-1">This update contains new functionality and features that were mentioned
              in the previous release.</p>
            <p class="text-body-1 font-weight-medium pt-4">New stuff to check out:</p>
            <ul>
              <li>View account information and edit your username from the
                <strong class="accent--text" @click="$router.push('/dashboard')"> MyDashboard </strong>page</li>
              <li>Users now have the ability to delete their account if they want to from the
                <strong class="accent--text" @click="$router.push('/dashboard')"> MyDashboard </strong>page</li>
              <li>The course<strong class="accent--text" @click="$router.push('/directory')"> directory </strong>(Desktop version)
                now has the option to clear all filters like the mobile version</li>
            </ul>
            <p class="text-body-1 font-weight-medium pt-4">Misc bug fixes:</p>
            <ul>
              <li>Fixed the bird count UI in the course directory where the padding around the bird icon
              would change based on the users auth status and whether they recommended the course as a bird</li>
              <li>Account login icon changes now when authenticated</li>
              <li>Fixed notifications not showing up on subsequent activations</li>
              <li>Bird icon padding issue is now fixed on the MyDashboard page (Mobile version)</li>
            </ul>
          </v-col>
        </v-row>
        <v-row><v-divider class="mt-6 mb-8"/></v-row>
        <!-- UPDATE - December 10, 2021 -->
        <v-row>
          <v-col lg="3" md="3" sm="3" cols="12" class="px-0 pb-0">
            <p class="font-weight-medium my-1">December 10, 2021</p>
            <p class="accent--text font-weight-bold">Version 0.1.0</p>
          </v-col>
          <v-col lg="9" md="9" sm="9" cols="12" class="px-0">
            <p class="text-h4 font-weight-medium mt-1 mb-2">UTSC Index - BETA Release!</p>
            <p class="text-body-1">FINALLY... The BETA release for UTSC Index is now available for testing. If
            users find or experience any bugs, have recommendations, or feature requests, please report them
            through the <strong class="accent--text" @click="$router.push('/feedback')"> feedback page</strong>.</p>
            <p class="text-body-1 font-weight-medium pt-4">The 4 main features that are currently available
              and waiting for testing:</p>
            <ul>
              <li><strong class="accent--text" @click="$router.push('/directory')">Bird Course Recommendations: </strong>
                Search, view, and recommended bird courses</li>
              <li><strong class="accent--text" @click="$router.push('/tree')">Prerequisite Trees: </strong>
                Quickly check course prerequisites for any course</li>
              <li><strong class="accent--text" @click="$router.push('/directory')">Course History: </strong>
                View when and who previously taught the course as well as the drop rate for that session</li>
              <li><strong class="accent--text" @click="$router.push('/droprate')">Drop Rates: </strong>
                Check the drop rate for courses and professors before deciding whether or not to take a course</li>
            </ul>
            <p class="text-body-1 font-weight-medium pt-4">Already in the works/under consideration:</p>
            <ul>
              <li>The ability to delete your account on the MyDashboard page</li>
              <li>A "Clear All" option in the course directory similar to the mobile version</li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'
import PageHeader from '@/components/PageHeader'

export default {
  name: 'Changelog',
  components: { PageHeader },
  setup () {
    return {
      store: useAllStores()
    }
  }
}
</script>

<style scoped>
  li {
    padding-bottom: 10px;
  }
  strong:hover {
    cursor: pointer;
  }

</style>
